/*Media*/
/*Colors*/
/*Common*/
html {
  font-size: 10px;
}
* {
  outline: none;
}
body,
ol,
ul,
p {
  padding: 0;
  margin: 0;
}
ul {
  list-style: none;
}
body {
  font-family: 'Roboto Slab', serif;
  min-width: 320px;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: subpixel-antialiased;
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 200;
  background-color: #4d4d4d;
}
.logo-wrp {
  width: 11rem;
}
.logo-wrp img {
  display: block;
  max-width: 100%;
}
@media print, (min-width: 768px) {
  .section .logo-wrp {
    margin-left: 7.5rem;
  }
}
@media (max-width: 1279px) {
  .logo-bottom-wrp {
    display: none;
  }
}
@media print, (min-width: 1280px) {
  .logo-bottom-wrp {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7.7rem;
    width: 16rem;
    margin: 0 auto;
  }
  .logo-bottom-wrp img {
    display: block;
    max-width: 100%;
  }
}
.section {
  background-color: #1e4381;
  padding: 2.5rem;
  color: #ffffff;
  overflow: hidden;
}
@media print, (min-width: 768px) {
  .section {
    padding: 5rem 7.5rem 7.5rem;
  }
}
@media print, (min-width: 1280px) {
  .section {
    padding: 0;
  }
}
.section .logo-wrp {
  position: absolute;
  opacity: 0;
  left: 0;
  transition: opacity .5s linear;
  top: 1.5rem;
  z-index: 10;
}
@media print, (min-width: 768px) {
  .section .logo-wrp {
    top: 5rem;
  }
}
.section.active .logo-wrp {
  opacity: 1;
}
.section p {
  margin-top: 0;
}
@media print, (min-width: 1280px) {
  .fullpage-wrapper .section:first-child .logo-wrp {
    display: none;
  }
}
.top-left-fifty {
  position: relative;
}
@media (max-width: 1279px) {
  .top-left-fifty {
    top: 50%;
  }
}
@media print, (min-width: 1280px) {
  .top-left-fifty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.top-left-fifty p {
  position: relative;
}
@media print, (min-width: 1280px) {
  .top-left-fifty p {
    transform: translateX(43%);
  }
}
.top-left-fifty p:before {
  content: '';
  position: absolute;
  background-color: #ffffff;
}
@media (max-width: 1279px) {
  .top-left-fifty p:before {
    top: -2rem;
    height: .1rem;
    width: 10.5rem;
  }
}
@media print, (min-width: 1280px) {
  .top-left-fifty p:before {
    top: -2.4rem;
    bottom: -2.4rem;
    width: .1rem;
    left: -2.5rem;
  }
}
@media print, (min-width: 1280px) {
  .top-left-fifty p.bold-text-top:before {
    top: -0.1rem;
  }
  .top-left-fifty p.bold-text-full:before {
    top: -0.1rem;
    bottom: -0.6rem;
  }
  .top-left-fifty p.bold-text-contacts:before {
    top: -1.8rem;
  }
}
.top-left-fifty p .line.bold {
  font-size: 5rem;
}
@media (min-width: 1024px) {
  .top-left-fifty p .line.bold.prd {
    font-size: 9.8rem;
  }
}
.bottom-hundrend-left-fifty {
  /*position: relative;
	top: 100%;
    transform: translateY(-100%);*/
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  font-size: 2.8rem;
  font-weight: 700;
  color: #ffffff;
}
@media (max-width: 767px) {
  #fp-nav,
  .fp-slidesNav {
    display: none;
  }
}
@media print, (min-width: 768px) {
  #fp-nav.right,
  .fp-slidesNav.right {
    right: 5.4rem;
  }
}
#fp-nav ul li,
.fp-slidesNav ul li {
  height: 5rem;
  width: 5rem;
  margin: 0;
}
#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  height: .4rem!important;
  width: .4rem!important;
  margin: 0!important;
  background-color: #b3b3b3;
}
#fp-nav ul li a.active span,
.fp-slidesNav ul li a.active span,
#fp-nav ul li a:hover span,
.fp-slidesNav ul li a:hover span {
  background-color: #da2128;
}
.lang-wrp {
  position: fixed;
  bottom: 2.5rem;
  right: 2.5rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  z-index: 120;
  cursor: pointer;
}
@media print, (min-width: 768px) {
  .lang-wrp {
    bottom: 7.5rem;
    right: 7.5rem;
  }
}
@media (max-width: 1279px) {
  .lang-wrp {
    display: none;
  }
}
@media print, (min-width: 1280px) {
  .lang-wrp {
    display: block !important;
  }
}
.lang-wrp.opened .link:not(.active) {
  visibility: visible;
  opacity: 1;
}
.lang-wrp.opened .link:not(.active):nth-child(2) {
  right: 3rem;
}
.lang-wrp.opened .link:not(.active):nth-child(3) {
  right: 6rem;
}
.lang-wrp li {
  display: inline-block;
  vertical-align: top;
}
.lang-wrp li.link {
  transition: right 0.5s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.35s cubic-bezier(0.165, 0.84, 0.44, 1), visibility 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}
.lang-wrp li.link:not(.active) {
  position: absolute;
  bottom: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
}
.lang-wrp li.link.active {
  position: relative;
  z-index: 10;
}
.lang-wrp a {
  color: #ffffff;
  font-weight: 400;
  text-decoration: none;
}
.lang-wrp a:hover {
  color: #ffffff;
}
.img-fit-cover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 4s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
}
.img-fit-cover.abs {
  position: absolute;
  top: 0;
  left: 0;
}
.img-fit-cover.more {
  transform: scale(1) translateZ(0);
}
.img-fit-cover.less {
  transform: scale(1.1) translateZ(0);
}
.section.active .img-fit-cover.more,
.section.fp-completely .img-fit-cover.more {
  transform: scale(1.1) translateZ(0);
}
.section.active .img-fit-cover.less,
.section.fp-completely .img-fit-cover.less {
  transform: scale(1) translateZ(0);
}
@media (max-width: 1279px) {
  .fullpage-wrapper .section:not(:first-child) .logo-wrp {
    display: none;
  }
}
.slidesNav-wrp {
  position: absolute;
  top: 90%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slidesNav-wrp .fp-slidesNav {
  position: relative;
  margin: 0 5rem !important;
}
@media (max-width: 1279px) {
  .slidesNav-wrp .fp-slidesNav {
    display: none;
  }
}
.fp-controlArrow {
  position: relative;
  margin-top: 0;
}
.fp-controlArrow.fp-prev,
.fp-controlArrow.fp-next {
  border-width: 0;
  width: 8.3rem;
  height: 1.6rem;
}
@media (max-width: 1279px) {
  .fp-controlArrow.fp-prev,
  .fp-controlArrow.fp-next {
    position: absolute;
  }
}
.fp-controlArrow.fp-prev:before,
.fp-controlArrow.fp-next:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 8.3rem;
  height: 1.6rem;
  background-image: url('../img/icon-slider-arrow.png');
  background-size: 100%;
}
.fp-controlArrow.fp-prev {
  left: 2.5rem;
}
@media print, (min-width: 768px) {
  .fp-controlArrow.fp-prev {
    left: 7.5rem;
  }
}
@media print, (min-width: 1280px) {
  .fp-controlArrow.fp-prev {
    left: auto;
  }
}
.fp-controlArrow.fp-next {
  transform: scaleX(-1);
  right: 2.5rem;
}
@media print, (min-width: 768px) {
  .fp-controlArrow.fp-next {
    right: 7.5rem;
  }
}
@media print, (min-width: 1280px) {
  .fp-controlArrow.fp-next {
    right: auto;
  }
}
.btn-main-menu {
  position: fixed;
  top: 2.2rem;
  right: 1.9rem;
  background-color: transparent;
  border: none;
  height: 4rem;
  padding: 0 .6rem;
  z-index: 120;
  cursor: pointer;
}
@media (max-width: 1279px) {
  .btn-main-menu {
    display: block !important;
  }
}
@media print, (min-width: 768px) {
  .btn-main-menu {
    top: 5.5rem;
    right: 6.9rem;
  }
}
@media print, (min-width: 1280px) {
  .btn-main-menu {
    display: none;
  }
  .btn-main-menu:hover .menu-logo span {
    width: 4.5rem !important;
  }
}
.btn-main-menu .menu-logo {
  position: relative;
  height: 0;
  width: 3.3rem;
}
.btn-main-menu .menu-logo span {
  transition: all 400ms ease 0ms;
  display: block;
  background-color: #ffffff;
  height: .2rem;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
header {
  position: relative;
}
header:not(.opened) .btn-main-menu span:first-child {
  width: 3.3rem;
  top: -0.7rem;
}
header:not(.opened) .btn-main-menu span:nth-child(2) {
  width: 3.3rem;
}
header:not(.opened) .btn-main-menu span:last-child {
  width: 3.3rem;
  top: .7rem;
}
header.opened .menu-xs {
  opacity: 1;
  visibility: visible;
}
header.opened .btn-main-menu span:first-child {
  transform: translateX(-50%) rotate(45deg);
  width: 3.3rem;
}
header.opened .btn-main-menu span:nth-child(2) {
  width: 0 !important;
}
header.opened .btn-main-menu span:last-child {
  transform: translateX(-50%) rotate(-45deg);
  width: 3.3rem;
}
@media (max-width: 1279px) {
  header .main-menu-lg {
    display: none !important;
  }
}
@media print, (min-width: 1280px) {
  header .main-menu-lg {
    z-index: 50;
    position: absolute;
    top: 7rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    transition: all .35s ease .4s;
  }
  header .main-menu-lg.hidden {
    top: 0;
    transform: translateY(-100%);
    transition: all .1s linear;
  }
  header .main-menu-lg li {
    display: inline-block;
    vertical-align: top;
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  header .main-menu-lg li a {
    color: #b3b3b3;
    text-decoration: none;
  }
  header .main-menu-lg li a:hover {
    color: #ffffff;
  }
  header .main-menu-lg li span {
    color: #ffffff;
  }
  header .main-menu-lg li + li {
    margin-left: 3.5rem;
  }
}
.menu-xs {
  opacity: 0;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-timing-function: ease;
  transition-duration: .4s;
  background-color: #4d4d4d;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 1.5rem 2.5rem;
  z-index: 110;
  overflow-y: auto;
}
@media print, (min-width: 768px) {
  .menu-xs {
    padding: 5rem 7.5rem;
  }
}
.menu-xs .logo-wrp {
  position: fixed;
  z-index: 10;
  top: 0rem;
  left: 0rem;
  padding-top: 1.5rem;
  padding-left: 2.5rem;
  padding-bottom: 1rem;
  padding-right: 5rem;
  background-color: #4d4d4d;
}
@media print, (min-width: 768px) {
  .menu-xs .logo-wrp {
    padding-top: 5rem;
    padding-left: 7.5rem;
  }
}
.menu-xs .menu-wrp {
  min-height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
@media print, (min-width: 1280px) {
  .menu-xs .menu-wrp {
    margin-left: calc(50% + 7.5rem);
  }
}
.menu-xs ul {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media print, (min-width: 1280px) {
  .menu-xs ul:before {
    content: '';
    position: absolute;
    top: 4.3rem;
    bottom: 4.3rem;
    left: -3.75rem;
    width: .1rem;
    background-color: #b3b3b3;
  }
}
.menu-xs li {
  font-size: 2.2rem;
  font-weight: 700;
  padding: 1.5rem 0;
}
@media print, (min-width: 768px) {
  .menu-xs li {
    font-size: 3.8rem;
  }
}
@media print, (min-width: 1280px) {
  .menu-xs li {
    font-size: 2.8rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
.menu-xs li a {
  color: #b3b3b3;
  text-decoration: none;
}
.menu-xs li a:hover {
  color: #ffffff;
}
.menu-xs li span {
  color: #ffffff;
}
.logo-wrp.main {
  position: fixed;
  z-index: 10;
  top: 1.5rem;
  left: 2.5rem;
  transition: opacity .5s linear;
}
.logo-wrp.main.active {
  opacity: 1;
}
@media print, (min-width: 768px) {
  .logo-wrp.main {
    top: 5rem;
    left: 7.5rem;
  }
}
@media print, (min-width: 1280px) {
  .logo-wrp.main {
    opacity: 0;
  }
}
.discount-first {
  font-size: 2.8rem;
  font-weight: 700;
}
.discount-first span.line {
  display: block;
}
@media print, (min-width: 768px) {
  .discount-first span.line {
    transform-origin: 0;
    transition: transform 0.6s ease-out 0.4s, opacity 0.6s ease-out 0.4s;
    opacity: 0;
    width: 9999px;
  }
  .discount-first span.line:first-child {
    transform: translateX(4rem) rotate(-2deg) scale(1.4);
  }
  .discount-first span.line:last-child {
    transform: translateX(4rem) rotate(2deg) scale(1.4);
  }
}
@media print, (min-width: 768px) {
  .discount-first p {
    max-width: 37rem;
  }
}
.section.active.discount-first span.line,
.section.fp-completely.discount-first span.line {
  opacity: 1;
  transform: translateX(0) rotate(0) scale(1);
}
@media print, (min-width: 768px) {
  .discount-second {
    padding: 0;
  }
}
.discount-second .content-wrp {
  height: 100%;
}
@media print, (min-width: 768px) {
  .discount-second .content-wrp {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
@media print, (min-width: 1024px) {
  .discount-second .content-wrp {
    flex-direction: row;
  }
}
@media (max-width: 767px) {
  .discount-second .content-wrp .image-wrp {
    display: none;
  }
}
@media print, (min-width: 768px) {
  .discount-second .content-wrp .image-wrp {
    overflow: hidden;
    height: 50%;
    flex-shrink: 1;
    flex-grow: 1;
  }
}
@media print, (min-width: 1024px) {
  .discount-second .content-wrp .image-wrp {
    height: 100%;
    width: 50%;
  }
}
@media print, (min-width: 768px) {
  .discount-second .content {
    height: 50%;
    flex-shrink: 1;
    flex-grow: 1;
    padding: 0 7.5rem 7.5rem;
    box-sizing: border-box;
  }
}
@media print, (min-width: 1024px) {
  .discount-second .content {
    height: 100%;
    width: 50%;
  }
}
.discount-second .content p {
  max-width: 30rem;
  opacity: 0;
  position: relative;
  top: 5rem;
}
.discount-second .content p:nth-child(1) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.5s;
}
.discount-second .content p:nth-child(2) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.35s;
}
.discount-second .content p:nth-child(3) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
}
.discount-second .content .range-list-wrp .range-list {
  opacity: 0;
  top: 5rem;
  position: relative;
}
.discount-second .content .range-list-wrp .range-list:nth-child(1) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.35s;
}
.discount-second .content .range-list-wrp .range-list:nth-child(2) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.35s;
}
.discount-second.active .content p {
  opacity: 1;
  top: 0;
}
.discount-second.active .content p:nth-child(1) {
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.35s;
}
.discount-second.active .content p:nth-child(2) {
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.5s;
}
.discount-second.active .content p:nth-child(3) {
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.65s;
}
.discount-second.active .range-list-wrp .range-list {
  opacity: 1;
  top: 0;
}
.discount-second.active .range-list-wrp .range-list:nth-child(1) {
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.5s;
}
.discount-second.active .range-list-wrp .range-list:nth-child(2) {
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.5s;
}
.discount-second .price {
  font-size: 5.8rem;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1;
}
@media print, (min-width: 768px) {
  .discount-second .price {
    font-size: 9rem;
    vertical-align: top;
    margin-bottom: 1rem;
  }
}
.discount-second .price span {
  display: inline-block;
  vertical-align: top;
  margin-left: .5rem;
}
@media print, (min-width: 768px) {
  .discount-second .price span {
    font-size: 5rem;
    margin-top: .9rem;
  }
}
.discount-second .header {
  font-size: 2.8rem;
  color: #b3b3b3;
  margin-bottom: 2rem;
  font-weight: normal;
}
@media print, (min-width: 768px) {
  .discount-second .header {
    font-weight: 700;
    margin-bottom: 6rem;
    position: relative;
  }
  .discount-second .header:after {
    content: '';
    position: absolute;
    bottom: -3rem;
    left: 0;
    height: 1px;
    width: 7.5rem;
    background-color: #b3b3b3;
  }
}
.discount-second .text,
.discount-second .range-list {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
  color: #b3b3b3;
  font-weight: normal;
}
@media print, (min-width: 1280px) {
  .discount-second .text,
  .discount-second .range-list {
    font-size: 1.6rem;
  }
}
.main-second {
  display: flex;
  align-items: flex-end;
}
@media print, (min-width: 1024px) {
  .main-second {
    align-items: center;
  }
}
.main-second .content-wrp {
  position: relative;
}
@media print, (min-width: 1024px) {
  .main-second .content-wrp {
    display: flex;
    max-width: 100rem;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
}
.main-second .content-wrp .lg {
  position: relative;
}
@media (max-width: 767px) {
  .main-second .content-wrp .lg {
    display: none;
  }
}
@media (max-width: 1023px) {
  .main-second .content-wrp .lg {
    margin-bottom: 12rem;
  }
}
@media print, (min-width: 1024px) {
  .main-second .content-wrp .lg {
    padding-right: 7rem;
    text-align: right;
  }
  .main-second .content-wrp .lg p {
    display: inline-block;
    text-align: left;
  }
}
.main-second .content-wrp .lg p {
  opacity: 0;
  position: relative;
  top: 5rem;
  min-width: 27.5rem;
}
.main-second .content-wrp .lg p:nth-child(1) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.5s;
}
.main-second .content-wrp .lg p:nth-child(2) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.35s;
}
.main-second .content-wrp .lg:after {
  content: '';
  position: absolute;
  bottom: -6rem;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #fff;
}
@media print, (min-width: 1024px) {
  .main-second .content-wrp .lg:after {
    top: -4rem;
    bottom: -4rem;
    left: auto;
    right: 0;
    height: auto;
    width: 1px;
  }
}
.main-second .content-wrp .lg .header {
  font-size: 7rem;
  font-weight: bold;
  line-height: 1.1;
}
.main-second .content-wrp .lg .header span:not(.square) {
  display: block;
  font-size: 2.6rem;
}
.main-second .content-wrp .lg .header span.square {
  display: inline-block;
  margin-left: 1.5rem;
  font-size: 5rem;
}
.main-second .content-wrp .lg .header + .header {
  margin-top: 3rem;
}
@media print, (min-width: 1024px) {
  .main-second .content-wrp .xs {
    padding-left: 7rem;
  }
  .main-second .content-wrp .xs p {
    max-width: 75%;
  }
}
.main-second .content-wrp .xs p {
  opacity: 0;
  position: relative;
  top: 5rem;
}
.main-second .content-wrp .xs p:nth-child(1) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.5s;
}
.main-second .content-wrp .xs p:nth-child(2) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.35s;
}
.main-second .content-wrp .xs p:nth-child(3) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
}
.main-second .content-wrp .xs .header {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
@media print, (min-width: 1280px) {
  .main-second .content-wrp .xs .header {
    margin-bottom: 3rem;
  }
}
.main-second .content-wrp .xs .text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
}
@media print, (min-width: 1280px) {
  .main-second .content-wrp .xs .text {
    font-size: 1.6rem;
  }
}
.main-second .content-wrp .xs .text + .text {
  margin-top: 1.5rem;
}
@media print, (min-width: 1280px) {
  .main-second .content-wrp .xs .text + .text {
    margin-top: 2.5rem;
  }
}
@media (max-width: 1023px) {
  .main-second .content-wrp .lg,
  .main-second .content-wrp .xs {
    max-width: 70%;
  }
}
@media print, (min-width: 1024px) {
  .main-second .content-wrp .lg,
  .main-second .content-wrp .xs {
    width: 50%;
    box-sizing: border-box;
  }
}
.main-second.active .content-wrp .xs p,
.main-second.active .content-wrp .lg p {
  opacity: 1;
  top: 0;
}
.main-second.active .content-wrp .xs p:nth-child(1),
.main-second.active .content-wrp .lg p:nth-child(1) {
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.35s;
}
.main-second.active .content-wrp .xs p:nth-child(2),
.main-second.active .content-wrp .lg p:nth-child(2) {
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.5s;
}
.main-second.active .content-wrp .xs p:nth-child(3),
.main-second.active .content-wrp .lg p:nth-child(3) {
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.65s;
}
.main-scheme {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7rem;
  padding-bottom: 7rem;
  background-color: #4d4d4d;
}
.main-scheme img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
@media print, (min-width: 1280px), (orientation: landscape) {
  .main-scheme img.xs {
    display: none;
  }
}
@media (orientation: portrait) {
  .main-scheme img.lg {
    display: none;
  }
}
section.video {
  background-color: #4d4d4d;
  position: relative;
}
section.video .play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 11.5rem;
  width: 11.5rem;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  cursor: pointer;
  transition: all .2s ease;
}
section.video .play:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 27.5px 0 27.5px 33px;
  border-color: transparent transparent transparent #4d4d4d;
}
section.video .play:hover {
  background-color: #fff;
}
section.video iframe {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  height: 50%;
  transform: translateY(-50%);
}
@media print, (min-width: 1280px) {
  section.video iframe {
    width: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.slider-wrp {
  background-color: #4d4d4d;
  padding-left: 0;
  padding-right: 0;
}
.slider-wrp.fp-completely img,
.slider-wrp.active img {
  transform: scale(1) translateZ(0) !important;
}
.slider-wrp .fp-slidesContainer .slide {
  display: flex;
  align-items: center;
}
.slider-wrp .fp-slidesContainer .slide:first-child img {
  transition: transform 4s cubic-bezier(0.23, 1, 0.32, 1) 0.3s;
  transform: scale(1.1) translateZ(0);
}
.slider-wrp .fp-slidesContainer .slide img {
  display: block;
  max-width: 100%;
}
@media print, (min-width: 1280px) {
  .slider-wrp .fp-slidesContainer .slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.main-last {
  display: flex;
  align-items: flex-end;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
@media print, (min-width: 768px) {
  .main-last {
    align-items: center;
    justify-content: center;
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
}
.main-last.active ul li {
  opacity: 1;
  top: 0;
}
@media print, (min-width: 1024px) {
  .main-last.active ul li:nth-child(1) {
    transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.35s;
  }
  .main-last.active ul li:nth-child(2) {
    transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.5s;
  }
  .main-last.active ul li:nth-child(3) {
    transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.65s;
  }
  .main-last.active ul li:nth-child(4) {
    transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.8s;
  }
}
.main-last ul {
  width: 70%;
  font-size: 0;
  position: relative;
}
@media print, (min-width: 1024px) {
  .main-last ul {
    display: flex;
    width: 80%;
  }
}
.main-last ul li {
  padding-left: 2rem;
  position: relative;
  font-size: 1.8rem;
  opacity: 0;
  top: 5rem;
  transition: all 0.3s cubic-bezier(0.3, 0.52, 0.52, 0.92);
}
@media (max-width: 1023px) and (min-width: 768px) {
  .main-last ul li:nth-child(-n+2) {
    margin-bottom: 6rem;
  }
}
@media print, (min-width: 768px) {
  .main-last ul li {
    padding-left: 0;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
  }
  .main-last ul li:nth-child(3) span {
    max-width: 20rem;
  }
  .main-last ul li:nth-child(4) span {
    max-width: 12rem;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .main-last ul li span {
    height: 7.2rem;
  }
}
@media print, (min-width: 1024px) {
  .main-last ul li {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.main-last ul li:before {
  content: '';
  position: absolute;
  top: 1.2rem;
  left: 0;
  height: .2rem;
  width: 1rem;
  background-color: #c2c2c2;
}
@media (max-width: 1023px) {
  .main-last ul li:before {
    display: none;
  }
}
@media print, (min-width: 1024px) {
  .main-last ul li:before {
    top: 0;
    bottom: 0;
    left: 50%;
    width: .1rem;
    height: auto;
    z-index: 0;
  }
}
@media print, (min-width: 768px) {
  .main-last ul li:nth-child(1) span {
    max-width: 18rem;
  }
  .main-last ul li:nth-child(2) span {
    max-width: 17rem;
  }
}
@media (max-width: 767px) {
  .main-last ul li + li {
    margin-top: 2rem;
  }
}
.main-last ul li span {
  display: block;
}
@media print, (min-width: 768px) {
  .main-last ul li span {
    margin: 0 auto 3rem;
  }
}
@media print, (min-width: 1024px) {
  .main-last ul li span {
    margin-bottom: 10rem;
    z-index: 10;
    background-color: #1e4381;
    padding-bottom: 2.5rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
.main-last ul li img {
  display: block;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .main-last ul li img {
    display: none;
  }
}
@media print, (min-width: 1024px) {
  .main-last ul li img {
    z-index: 10;
    background-color: #1e4381;
    padding-top: 2.5rem;
  }
}
.product-second .header {
  color: #ffffff;
}
@media (min-width: 768px) and (max-width: 1279px) {
  .product-second .range-list-wrp {
    display: flex;
  }
}
.product-second .range-list {
  line-height: 1.5;
}
@media print, (min-width: 768px) {
  .product-second .range-list {
    font-size: 1.6rem;
  }
}
.product-second .range-list + .range-list {
  margin-top: 2rem;
}
@media print, (min-width: 768px) {
  .product-second .range-list + .range-list {
    margin-top: 0;
    margin-left: 5rem;
  }
}
@media print, (min-width: 1280px) {
  .product-second .range-list + .range-list {
    margin-top: 2rem;
    margin-left: 0;
  }
}
.products-fish.active .content-wrp .center-part-wrp {
  opacity: 1;
  top: 50%;
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.35s;
}
.products-fish .content-wrp {
  height: 100%;
}
.products-fish .content-wrp .content {
  height: 100%;
  z-index: 10;
  position: relative;
  display: flex;
  align-items: flex-end;
}
@media print, (min-width: 768px) {
  .products-fish .content-wrp .content {
    justify-content: center;
  }
}
.products-fish .content-wrp .center-part-wrp {
  position: absolute;
  opacity: 0;
  top: 55%;
  transform: translateY(-50%);
  transition: all .2s ease;
}
@media print, (min-width: 768px) {
  .products-fish .content-wrp .center-part-wrp {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .products-fish .content-wrp .center-part-wrp p {
    text-align: center;
  }
}
@media print, (min-width: 1280px) {
  .products-fish .content-wrp .center-part-wrp {
    flex-direction: row;
  }
}
.products-fish .content-wrp .center-part-wrp .fish {
  font-size: 5.8rem;
  font-weight: bold;
  order: 2;
  position: relative;
  top: 5rem;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.5s;
}
@media (max-width: 767px) {
  .products-fish .content-wrp .center-part-wrp .fish {
    line-height: 1;
  }
}
@media print, (min-width: 768px) {
  .products-fish .content-wrp .center-part-wrp .fish {
    font-size: 9.8rem;
  }
}
.products-fish .content-wrp .center-part-wrp .fish:before,
.products-fish .content-wrp .center-part-wrp .fish:after {
  content: '';
  position: absolute;
  height: .1rem;
  width: 12rem;
  background-color: #fff;
}
@media (max-width: 767px) {
  .products-fish .content-wrp .center-part-wrp .fish:before,
  .products-fish .content-wrp .center-part-wrp .fish:after {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .products-fish .content-wrp .center-part-wrp .fish:before,
  .products-fish .content-wrp .center-part-wrp .fish:after {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
@media print, (min-width: 1280px) {
  .products-fish .content-wrp .center-part-wrp .fish:before,
  .products-fish .content-wrp .center-part-wrp .fish:after {
    height: auto;
    width: .1rem;
    top: -1rem !important;
    bottom: -1rem !important;
    margin: 0;
  }
}
.products-fish .content-wrp .center-part-wrp .fish:before {
  top: -3rem;
}
@media print, (min-width: 1280px) {
  .products-fish .content-wrp .center-part-wrp .fish:before {
    right: -6rem;
  }
}
.products-fish .content-wrp .center-part-wrp .fish:after {
  bottom: -4rem;
}
@media print, (min-width: 1280px) {
  .products-fish .content-wrp .center-part-wrp .fish:after {
    left: -6rem;
  }
}
.products-fish .content-wrp .center-part-wrp .fish-prod {
  font-size: 2.8rem;
  position: relative;
  top: 5rem;
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.5s;
}
@media (max-width: 767px) {
  .products-fish .content-wrp .center-part-wrp .fish-prod {
    line-height: 1.5;
  }
}
@media print, (min-width: 768px) {
  .products-fish .content-wrp .center-part-wrp .fish-prod {
    font-size: 3.6rem;
  }
}
@media print, (min-width: 768px) {
  .products-fish .content-wrp .center-part-wrp .fish-prod.f {
    order: 1;
    margin-bottom: 8rem;
    max-width: 20rem;
  }
}
@media print, (min-width: 1280px) {
  .products-fish .content-wrp .center-part-wrp .fish-prod.f {
    margin-bottom: 0;
    margin-right: 12rem;
  }
}
@media print, (min-width: 768px) {
  .products-fish .content-wrp .center-part-wrp .fish-prod.s {
    max-width: 15rem;
    margin-top: 8rem;
    order: 3;
  }
}
@media print, (min-width: 1280px) {
  .products-fish .content-wrp .center-part-wrp .fish-prod.s {
    margin-top: 0;
    margin-left: 12rem;
    padding-right: 5rem;
  }
}
.products-fish .content-wrp .bottom-part {
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem;
}
@media print, (min-width: 768px) {
  .products-fish .content-wrp .bottom-part {
    text-align: center;
    max-width: 37rem;
  }
}
@media print, (min-width: 1280px) {
  .products-fish .content-wrp .bottom-part {
    margin-bottom: 7.5rem;
    max-width: 41rem;
    font-size: 1.6rem;
  }
}
.products-fish.active .content-wrp .center-part-wrp .fish {
  top: 0;
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.35s;
}
.products-fish.active .content-wrp .center-part-wrp .fish-prod {
  top: 0;
  opacity: 1;
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.7s;
}
.cafe-first p span.line {
  font-size: 8.2rem;
}
@media print, (min-width: 768px) {
  .cafe-first p span.line {
    font-size: 9.8rem;
  }
}
.cafe-last .center-part-wrp p {
  font-size: 2.8rem;
}
@media print, (min-width: 768px) {
  .cafe-last .center-part-wrp p {
    font-size: 4.8rem;
  }
}
@media print, (min-width: 1280px) {
  .cafe-last .center-part-wrp p {
    max-width: 80rem;
  }
}
.cafe-last .center-part-wrp p span {
  font-weight: bold;
  display: block;
}
@media print, (min-width: 768px) {
  .cafe-last .content-wrp .bottom-part {
    font-size: 1.6rem;
    padding: 1rem 3rem;
    background-color: rgba(166, 31, 27, 0.6);
  }
  .cafe-last .content-wrp .bottom-part .block-line {
    display: block;
  }
}
@media print, (min-width: 768px) and print, (min-width: 1280px) {
  .cafe-last .content-wrp .bottom-part {
    padding: 2rem 4rem;
    margin-bottom: 8rem;
  }
}
@media print, (min-width: 1280px) {
  .contacts-map .bottom-part {
    margin-bottom: 7.5rem;
  }
}
.contacts-map .bottom-part span {
  display: block;
  opacity: 0;
  position: relative;
  top: 5rem;
}
.contacts-map .bottom-part span:nth-child(1) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.5s;
}
.contacts-map .bottom-part span:nth-child(2) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.35s;
}
.contacts-map .bottom-part span:nth-child(3) {
  transition: all 0.75s cubic-bezier(0.23, 1, 0.32, 1) 0.2s;
}
@media print, (min-width: 768px) {
  .contacts-map .bottom-part span {
    position: relative;
    font-size: 1.6rem;
  }
}
.contacts-map .bottom-part span + span {
  margin-top: 2rem;
}
@media print, (min-width: 768px) {
  .contacts-map .bottom-part span + span {
    margin-top: 6rem;
  }
  .contacts-map .bottom-part span + span:before {
    content: '';
    position: absolute;
    height: .1rem;
    width: 12rem;
    background-color: #fff;
    top: -3rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.contacts-map .bottom-part span.address {
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  font-size: 2.2rem;
}
.contacts-map .bottom-part span .block-line {
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
}
.contacts-map.active .bottom-part span {
  opacity: 1;
  top: 0;
}
.contacts-map.active .bottom-part span:nth-child(1) {
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.35s;
}
.contacts-map.active .bottom-part span:nth-child(2) {
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.5s;
}
.contacts-map.active .bottom-part span:nth-child(3) {
  transition: all 0.5s cubic-bezier(0.3, 0.52, 0.52, 0.92) 0.65s;
}
